import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c590b6c = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _22dfeaaa = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _8b15183c = () => interopDefault(import('../pages/company-panel/index.vue' /* webpackChunkName: "pages/company-panel/index" */))
const _0951240c = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _e4a44840 = () => interopDefault(import('../pages/crews/index.vue' /* webpackChunkName: "pages/crews/index" */))
const _1f5a0af3 = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _84c12828 = () => interopDefault(import('../pages/hazard-register/index.vue' /* webpackChunkName: "pages/hazard-register/index" */))
const _9cfba362 = () => interopDefault(import('../pages/incidents/index.vue' /* webpackChunkName: "pages/incidents/index" */))
const _4dd667d4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0a8a9834 = () => interopDefault(import('../pages/machines/index.vue' /* webpackChunkName: "pages/machines/index" */))
const _58b72eaf = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _aa0f98c0 = () => interopDefault(import('../pages/safety-alerts/index.vue' /* webpackChunkName: "pages/safety-alerts/index" */))
const _28f95e8b = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _ac6fab8c = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _49511e5e = () => interopDefault(import('../pages/summary.vue' /* webpackChunkName: "pages/summary" */))
const _887352d8 = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _350770f4 = () => interopDefault(import('../pages/summary/crews.vue' /* webpackChunkName: "pages/summary/crews" */))
const _7d0f3f23 = () => interopDefault(import('../pages/summary/incidents.vue' /* webpackChunkName: "pages/summary/incidents" */))
const _97cde70c = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _7a1c549e = () => interopDefault(import('../pages/training-competency/index.vue' /* webpackChunkName: "pages/training-competency/index" */))
const _606207a2 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _3601b245 = () => interopDefault(import('../pages/company-panel/view.vue' /* webpackChunkName: "pages/company-panel/view" */))
const _5c4eb108 = () => interopDefault(import('../pages/company-panel/view/index.vue' /* webpackChunkName: "pages/company-panel/view/index" */))
const _44d7d839 = () => interopDefault(import('../pages/company-panel/view/edit-details.vue' /* webpackChunkName: "pages/company-panel/view/edit-details" */))
const _25521105 = () => interopDefault(import('../pages/company-panel/view/manage-access/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/index" */))
const _29186604 = () => interopDefault(import('../pages/company-panel/view/manage-crews.vue' /* webpackChunkName: "pages/company-panel/view/manage-crews" */))
const _cfdb5852 = () => interopDefault(import('../pages/company-panel/view/manage-employees.vue' /* webpackChunkName: "pages/company-panel/view/manage-employees" */))
const _5349367c = () => interopDefault(import('../pages/company-panel/view/manage-access/view.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view" */))
const _7f85d1c5 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/index" */))
const _d66e831e = () => interopDefault(import('../pages/company-panel/view/manage-access/view/groups.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/groups" */))
const _eb76c348 = () => interopDefault(import('../pages/company/view.vue' /* webpackChunkName: "pages/company/view" */))
const _13402042 = () => interopDefault(import('../pages/company/view/index.vue' /* webpackChunkName: "pages/company/view/index" */))
const _4dcfb1f8 = () => interopDefault(import('../pages/company/view/files.vue' /* webpackChunkName: "pages/company/view/files" */))
const _4090b79d = () => interopDefault(import('../pages/company/view/objectives.vue' /* webpackChunkName: "pages/company/view/objectives" */))
const _0ab14ff5 = () => interopDefault(import('../pages/company/view/policy.vue' /* webpackChunkName: "pages/company/view/policy" */))
const _390bf9fe = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _f6696546 = () => interopDefault(import('../pages/incidents/new.vue' /* webpackChunkName: "pages/incidents/new" */))
const _39cd5303 = () => interopDefault(import('../pages/machines/add.vue' /* webpackChunkName: "pages/machines/add" */))
const _b8171a24 = () => interopDefault(import('../pages/safety-alerts/new.vue' /* webpackChunkName: "pages/safety-alerts/new" */))
const _3dd6fdeb = () => interopDefault(import('../pages/sites/register.vue' /* webpackChunkName: "pages/sites/register" */))
const _310a282e = () => interopDefault(import('../pages/sites/register/index.vue' /* webpackChunkName: "pages/sites/register/index" */))
const _dcff8b9a = () => interopDefault(import('../pages/sites/register/_forestid.vue' /* webpackChunkName: "pages/sites/register/_forestid" */))
const _e17f0c84 = () => interopDefault(import('../pages/vehicles/add.vue' /* webpackChunkName: "pages/vehicles/add" */))
const _6f1c950e = () => interopDefault(import('../pages/reminders/goto/crewgear.vue' /* webpackChunkName: "pages/reminders/goto/crewgear" */))
const _4196a546 = () => interopDefault(import('../pages/reminders/goto/employeegear.vue' /* webpackChunkName: "pages/reminders/goto/employeegear" */))
const _549ec0ce = () => interopDefault(import('../pages/company-panel/manage-access/group/_groupuuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/group/_groupuuid" */))
const _29a6d838 = () => interopDefault(import('../pages/company-panel/manage-access/user/_useruuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/user/_useruuid" */))
const _a951ad4e = () => interopDefault(import('../pages/company/program/_versionid/index.vue' /* webpackChunkName: "pages/company/program/_versionid/index" */))
const _16bd748a = () => interopDefault(import('../pages/company/program/_versionid/policy.vue' /* webpackChunkName: "pages/company/program/_versionid/policy" */))
const _19031744 = () => interopDefault(import('../pages/employees/_id/index.vue' /* webpackChunkName: "pages/employees/_id/index" */))
const _23ed89ba = () => interopDefault(import('../pages/incidents/_id/index.vue' /* webpackChunkName: "pages/incidents/_id/index" */))
const _de4d5528 = () => interopDefault(import('../pages/sites/_siteid/index.vue' /* webpackChunkName: "pages/sites/_siteid/index" */))
const _10dbc552 = () => interopDefault(import('../pages/summarynew/_drillid.vue' /* webpackChunkName: "pages/summarynew/_drillid" */))
const _48a6fa0d = () => interopDefault(import('../pages/contractors/_companyid/agreement/index.vue' /* webpackChunkName: "pages/contractors/_companyid/agreement/index" */))
const _447d34de = () => interopDefault(import('../pages/contractors/_companyid/reviews/index.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/index" */))
const _331acbdc = () => interopDefault(import('../pages/crews/_crewid/documents.vue' /* webpackChunkName: "pages/crews/_crewid/documents" */))
const _1493cb15 = () => interopDefault(import('../pages/crews/_crewid/documents/index.vue' /* webpackChunkName: "pages/crews/_crewid/documents/index" */))
const _1167fb8c = () => interopDefault(import('../pages/crews/_crewid/documents/files.vue' /* webpackChunkName: "pages/crews/_crewid/documents/files" */))
const _4507e8f2 = () => interopDefault(import('../pages/crews/_crewid/documents/gear-tracker.vue' /* webpackChunkName: "pages/crews/_crewid/documents/gear-tracker" */))
const _9fb45422 = () => interopDefault(import('../pages/crews/_crewid/documents/safe-start.vue' /* webpackChunkName: "pages/crews/_crewid/documents/safe-start" */))
const _2ed41f52 = () => interopDefault(import('../pages/crews/_crewid/documents/substance-inv.vue' /* webpackChunkName: "pages/crews/_crewid/documents/substance-inv" */))
const _604b6d67 = () => interopDefault(import('../pages/crews/_crewid/monthly.vue' /* webpackChunkName: "pages/crews/_crewid/monthly" */))
const _1fb40aaa = () => interopDefault(import('../pages/crews/_crewid/monthly/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/index" */))
const _12d1f650 = () => interopDefault(import('../pages/crews/_crewid/monthly/audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/audits" */))
const _ae0b68e8 = () => interopDefault(import('../pages/crews/_crewid/monthly/emergency-drills.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/emergency-drills" */))
const _a79230ea = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-meetings.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-meetings" */))
const _2f8f1c0b = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-review.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-review" */))
const _4d9a154b = () => interopDefault(import('../pages/crews/_crewid/monthly/sbos.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/sbos" */))
const _bb60d5a4 = () => interopDefault(import('../pages/crews/_crewid/monthly/system-audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/system-audits" */))
const _599b0e64 = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/index" */))
const _7aadf79c = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/toolbox.unit.js' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/toolbox.unit" */))
const _500eab2e = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _37d8b249 = () => interopDefault(import('../pages/employees/_id/view.vue' /* webpackChunkName: "pages/employees/_id/view" */))
const _347e9e0c = () => interopDefault(import('../pages/employees/_id/view/index.vue' /* webpackChunkName: "pages/employees/_id/view/index" */))
const _a4c5525e = () => interopDefault(import('../pages/employees/_id/view/compliance.vue' /* webpackChunkName: "pages/employees/_id/view/compliance" */))
const _521a63d2 = () => interopDefault(import('../pages/employees/_id/view/drug-alcohol.vue' /* webpackChunkName: "pages/employees/_id/view/drug-alcohol" */))
const _1736d531 = () => interopDefault(import('../pages/employees/_id/view/files.vue' /* webpackChunkName: "pages/employees/_id/view/files" */))
const _5cebea2e = () => interopDefault(import('../pages/employees/_id/view/forms.vue' /* webpackChunkName: "pages/employees/_id/view/forms" */))
const _41dc63fb = () => interopDefault(import('../pages/employees/_id/view/induction.vue' /* webpackChunkName: "pages/employees/_id/view/induction" */))
const _fd0ec662 = () => interopDefault(import('../pages/employees/_id/view/linked.vue' /* webpackChunkName: "pages/employees/_id/view/linked" */))
const _6b4937cb = () => interopDefault(import('../pages/employees/_id/view/medical.vue' /* webpackChunkName: "pages/employees/_id/view/medical" */))
const _72563c8a = () => interopDefault(import('../pages/employees/_id/view/ppe-tracker.vue' /* webpackChunkName: "pages/employees/_id/view/ppe-tracker" */))
const _0eeb43d4 = () => interopDefault(import('../pages/employees/_id/view/record-learning.vue' /* webpackChunkName: "pages/employees/_id/view/record-learning" */))
const _3d20ce85 = () => interopDefault(import('../pages/employees/_id/view/training-records.vue' /* webpackChunkName: "pages/employees/_id/view/training-records" */))
const _860d0b5c = () => interopDefault(import('../pages/incidents/_id/edit.vue' /* webpackChunkName: "pages/incidents/_id/edit" */))
const _45b98f58 = () => interopDefault(import('../pages/machines/_machineid/edit.vue' /* webpackChunkName: "pages/machines/_machineid/edit" */))
const _d42f66ce = () => interopDefault(import('../pages/machines/_machineid/files.vue' /* webpackChunkName: "pages/machines/_machineid/files" */))
const _6e7fd0b4 = () => interopDefault(import('../pages/machines/_machineid/induction/index.vue' /* webpackChunkName: "pages/machines/_machineid/induction/index" */))
const _6c3682c9 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/index.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/index" */))
const _6636777b = () => interopDefault(import('../pages/sites/_siteid/view.vue' /* webpackChunkName: "pages/sites/_siteid/view" */))
const _2ca79484 = () => interopDefault(import('../pages/sites/_siteid/view/index.vue' /* webpackChunkName: "pages/sites/_siteid/view/index" */))
const _48f1170d = () => interopDefault(import('../pages/sites/_siteid/view/covid-qr.vue' /* webpackChunkName: "pages/sites/_siteid/view/covid-qr" */))
const _1c7620dc = () => interopDefault(import('../pages/sites/_siteid/view/emergency-information.vue' /* webpackChunkName: "pages/sites/_siteid/view/emergency-information" */))
const _6737263a = () => interopDefault(import('../pages/sites/_siteid/view/files.vue' /* webpackChunkName: "pages/sites/_siteid/view/files" */))
const _16c4bda2 = () => interopDefault(import('../pages/sites/_siteid/view/hazard-id.vue' /* webpackChunkName: "pages/sites/_siteid/view/hazard-id" */))
const _544227fb = () => interopDefault(import('../pages/sites/_siteid/view/more-forms.vue' /* webpackChunkName: "pages/sites/_siteid/view/more-forms" */))
const _b7ce43ee = () => interopDefault(import('../pages/sites/_siteid/view/visitors.vue' /* webpackChunkName: "pages/sites/_siteid/view/visitors" */))
const _74887a88 = () => interopDefault(import('../pages/sites/_siteid/visitor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor" */))
const _563e09ea = () => interopDefault(import('../pages/sites/_siteid/visitor/index.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/index" */))
const _c3e25654 = () => interopDefault(import('../pages/sites/_siteid/visitor/addressbook.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/addressbook" */))
const _40aaf68c = () => interopDefault(import('../pages/sites/_siteid/visitor/contractor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/contractor" */))
const _454f500a = () => interopDefault(import('../pages/sites/_siteid/visitor/infrequent.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/infrequent" */))
const _789dd98e = () => interopDefault(import('../pages/vehicles/_vehicleid/edit.vue' /* webpackChunkName: "pages/vehicles/_vehicleid/edit" */))
const _aeb6a8a8 = () => interopDefault(import('../pages/contractors/_companyid/reviews/new.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/new" */))
const _6378cec2 = () => interopDefault(import('../pages/crews/_crewid/audits/new.vue' /* webpackChunkName: "pages/crews/_crewid/audits/new" */))
const _aa04bcec = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/new.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/new" */))
const _ec4ff190 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/new.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/new" */))
const _2d3eece7 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/new" */))
const _1753c349 = () => interopDefault(import('../pages/crews/_crewid/safety-review/health-representative-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/health-representative-review" */))
const _d56ef0c4 = () => interopDefault(import('../pages/crews/_crewid/safety-review/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/new" */))
const _50b62249 = () => interopDefault(import('../pages/crews/_crewid/safety-review/site-supervisor-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/site-supervisor-review" */))
const _3fef60f6 = () => interopDefault(import('../pages/crews/_crewid/safety-review/worker-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/worker-review" */))
const _8dca2c8a = () => interopDefault(import('../pages/crews/_crewid/safety-sheets/add.vue' /* webpackChunkName: "pages/crews/_crewid/safety-sheets/add" */))
const _11385edd = () => interopDefault(import('../pages/crews/_crewid/systems-audit/new.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/new" */))
const _ffb20064 = () => interopDefault(import('../pages/employees/_id/forms/da-consent.vue' /* webpackChunkName: "pages/employees/_id/forms/da-consent" */))
const _7f2f60bd = () => interopDefault(import('../pages/employees/_id/forms/rol-request.vue' /* webpackChunkName: "pages/employees/_id/forms/rol-request" */))
const _1fdf6c6d = () => interopDefault(import('../pages/employees/_id/medical-information/edit.vue' /* webpackChunkName: "pages/employees/_id/medical-information/edit" */))
const _17605d26 = () => interopDefault(import('../pages/employees/_id/record-learning/request.vue' /* webpackChunkName: "pages/employees/_id/record-learning/request" */))
const _35e77ee6 = () => interopDefault(import('../pages/machines/_machineid/induction/edit.vue' /* webpackChunkName: "pages/machines/_machineid/induction/edit" */))
const _b0c30f52 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/new.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/new" */))
const _a0e73bc2 = () => interopDefault(import('../pages/sites/_siteid/emergency-information/edit.vue' /* webpackChunkName: "pages/sites/_siteid/emergency-information/edit" */))
const _5e44836f = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/new.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/new" */))
const _5e7e5c19 = () => interopDefault(import('../pages/sites/_siteid/hazard-id/edit.vue' /* webpackChunkName: "pages/sites/_siteid/hazard-id/edit" */))
const _453db115 = () => interopDefault(import('../pages/sites/_siteid/presite/new.vue' /* webpackChunkName: "pages/sites/_siteid/presite/new" */))
const _6c389b09 = () => interopDefault(import('../pages/crews/_crewid/sbos/new/_formid.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/new/_formid" */))
const _704fa050 = () => interopDefault(import('../pages/crews/_crewid/toolbox/new/_toolboxid.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/new/_toolboxid" */))
const _10f25ab5 = () => interopDefault(import('../pages/crews/_crewid/sbos/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/view/_formid/_id" */))
const _67f95f31 = () => interopDefault(import('../pages/crews/_crewid/toolbox/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/view/_formid/_id" */))
const _d6225274 = () => interopDefault(import('../pages/contractors/_companyid/reviews/_id.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/_id" */))
const _8ae4788e = () => interopDefault(import('../pages/crews/_crewid/audits/_id.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_id" */))
const _5b27a306 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/_drillid.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/_drillid" */))
const _76223252 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/_id.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/_id" */))
const _4c536ac0 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/_meetingid.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/_meetingid" */))
const _04faec12 = () => interopDefault(import('../pages/crews/_crewid/systems-audit/_id.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/_id" */))
const _4a8eae89 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/_id.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/_id" */))
const _3187dc2f = () => interopDefault(import('../pages/sites/_siteid/presite/_id.vue' /* webpackChunkName: "pages/sites/_siteid/presite/_id" */))
const _02eea3e4 = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting" */))
const _bdf0855e = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.unit.js' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting.unit" */))
const _707919fc = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/_type.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/_type" */))
const _988d2886 = () => interopDefault(import('../pages/contractors/_companyid/_id/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/index" */))
const _0092face = () => interopDefault(import('../pages/sop/_id/_version.vue' /* webpackChunkName: "pages/sop/_id/_version" */))
const _14c1d30a = () => interopDefault(import('../pages/contractors/_companyid/_id/view.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view" */))
const _b0bb2be6 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/index" */))
const _ef0d7bde = () => interopDefault(import('../pages/contractors/_companyid/_id/view/file.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/file" */))
const _44add6ea = () => interopDefault(import('../pages/contractors/_companyid/_id/view/forms.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/forms" */))
const _266c4927 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/inductions.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/inductions" */))
const _44515ab2 = () => interopDefault(import('../pages/contractors/_companyid/_id/worker-information/edit.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/worker-information/edit" */))
const _f7a27686 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _7c590b6c,
    name: "address-book"
  }, {
    path: "/company",
    component: _22dfeaaa,
    name: "company"
  }, {
    path: "/company-panel",
    component: _8b15183c,
    name: "company-panel"
  }, {
    path: "/contractors",
    component: _0951240c,
    name: "contractors"
  }, {
    path: "/crews",
    component: _e4a44840,
    name: "crews"
  }, {
    path: "/employees",
    component: _1f5a0af3,
    name: "employees"
  }, {
    path: "/hazard-register",
    component: _84c12828,
    name: "hazard-register"
  }, {
    path: "/incidents",
    component: _9cfba362,
    name: "incidents"
  }, {
    path: "/login",
    component: _4dd667d4,
    name: "login"
  }, {
    path: "/machines",
    component: _0a8a9834,
    name: "machines"
  }, {
    path: "/reminders",
    component: _58b72eaf,
    name: "reminders"
  }, {
    path: "/safety-alerts",
    component: _aa0f98c0,
    name: "safety-alerts"
  }, {
    path: "/settings",
    component: _28f95e8b,
    name: "settings"
  }, {
    path: "/sites",
    component: _ac6fab8c,
    name: "sites"
  }, {
    path: "/summary",
    component: _49511e5e,
    children: [{
      path: "",
      component: _887352d8,
      name: "summary"
    }, {
      path: "crews",
      component: _350770f4,
      name: "summary-crews"
    }, {
      path: "incidents",
      component: _7d0f3f23,
      name: "summary-incidents"
    }]
  }, {
    path: "/support",
    component: _97cde70c,
    name: "support"
  }, {
    path: "/training-competency",
    component: _7a1c549e,
    name: "training-competency"
  }, {
    path: "/vehicles",
    component: _606207a2,
    name: "vehicles"
  }, {
    path: "/company-panel/view",
    component: _3601b245,
    children: [{
      path: "",
      component: _5c4eb108,
      name: "company-panel-view"
    }, {
      path: "edit-details",
      component: _44d7d839,
      name: "company-panel-view-edit-details"
    }, {
      path: "manage-access",
      component: _25521105,
      name: "company-panel-view-manage-access"
    }, {
      path: "manage-crews",
      component: _29186604,
      name: "company-panel-view-manage-crews"
    }, {
      path: "manage-employees",
      component: _cfdb5852,
      name: "company-panel-view-manage-employees"
    }, {
      path: "manage-access/view",
      component: _5349367c,
      children: [{
        path: "",
        component: _7f85d1c5,
        name: "company-panel-view-manage-access-view"
      }, {
        path: "groups",
        component: _d66e831e,
        name: "company-panel-view-manage-access-view-groups"
      }]
    }]
  }, {
    path: "/company/view",
    component: _eb76c348,
    children: [{
      path: "",
      component: _13402042,
      name: "company-view"
    }, {
      path: "files",
      component: _4dcfb1f8,
      name: "company-view-files"
    }, {
      path: "objectives",
      component: _4090b79d,
      name: "company-view-objectives"
    }, {
      path: "policy",
      component: _0ab14ff5,
      name: "company-view-policy"
    }]
  }, {
    path: "/employees/new",
    component: _390bf9fe,
    name: "employees-new"
  }, {
    path: "/incidents/new",
    component: _f6696546,
    name: "incidents-new"
  }, {
    path: "/machines/add",
    component: _39cd5303,
    name: "machines-add"
  }, {
    path: "/safety-alerts/new",
    component: _b8171a24,
    name: "safety-alerts-new"
  }, {
    path: "/sites/register",
    component: _3dd6fdeb,
    children: [{
      path: "",
      component: _310a282e,
      name: "sites-register"
    }, {
      path: ":forestid",
      component: _dcff8b9a,
      name: "sites-register-forestid"
    }]
  }, {
    path: "/vehicles/add",
    component: _e17f0c84,
    name: "vehicles-add"
  }, {
    path: "/reminders/goto/crewgear",
    component: _6f1c950e,
    name: "reminders-goto-crewgear"
  }, {
    path: "/reminders/goto/employeegear",
    component: _4196a546,
    name: "reminders-goto-employeegear"
  }, {
    path: "/company-panel/manage-access/group/:groupuuid?",
    component: _549ec0ce,
    name: "company-panel-manage-access-group-groupuuid"
  }, {
    path: "/company-panel/manage-access/user/:useruuid?",
    component: _29a6d838,
    name: "company-panel-manage-access-user-useruuid"
  }, {
    path: "/company/program/:versionid",
    component: _a951ad4e,
    name: "company-program-versionid"
  }, {
    path: "/company/program/:versionid?/policy",
    component: _16bd748a,
    name: "company-program-versionid-policy"
  }, {
    path: "/employees/:id",
    component: _19031744,
    name: "employees-id"
  }, {
    path: "/incidents/:id",
    component: _23ed89ba,
    name: "incidents-id"
  }, {
    path: "/sites/:siteid",
    component: _de4d5528,
    name: "sites-siteid"
  }, {
    path: "/summarynew/:drillid?",
    component: _10dbc552,
    name: "summarynew-drillid"
  }, {
    path: "/contractors/:companyid/agreement",
    component: _48a6fa0d,
    name: "contractors-companyid-agreement"
  }, {
    path: "/contractors/:companyid/reviews",
    component: _447d34de,
    name: "contractors-companyid-reviews"
  }, {
    path: "/crews/:crewid/documents",
    component: _331acbdc,
    children: [{
      path: "",
      component: _1493cb15,
      name: "crews-crewid-documents"
    }, {
      path: "files",
      component: _1167fb8c,
      name: "crews-crewid-documents-files"
    }, {
      path: "gear-tracker",
      component: _4507e8f2,
      name: "crews-crewid-documents-gear-tracker"
    }, {
      path: "safe-start",
      component: _9fb45422,
      name: "crews-crewid-documents-safe-start"
    }, {
      path: "substance-inv",
      component: _2ed41f52,
      name: "crews-crewid-documents-substance-inv"
    }]
  }, {
    path: "/crews/:crewid/monthly",
    component: _604b6d67,
    children: [{
      path: "",
      component: _1fb40aaa,
      name: "crews-crewid-monthly"
    }, {
      path: "audits",
      component: _12d1f650,
      name: "crews-crewid-monthly-audits"
    }, {
      path: "emergency-drills",
      component: _ae0b68e8,
      name: "crews-crewid-monthly-emergency-drills"
    }, {
      path: "safety-meetings",
      component: _a79230ea,
      name: "crews-crewid-monthly-safety-meetings"
    }, {
      path: "safety-review",
      component: _2f8f1c0b,
      name: "crews-crewid-monthly-safety-review"
    }, {
      path: "sbos",
      component: _4d9a154b,
      name: "crews-crewid-monthly-sbos"
    }, {
      path: "system-audits",
      component: _bb60d5a4,
      name: "crews-crewid-monthly-system-audits"
    }, {
      path: "toolbox",
      component: _599b0e64,
      name: "crews-crewid-monthly-toolbox"
    }, {
      path: "toolbox/toolbox.unit",
      component: _7aadf79c,
      name: "crews-crewid-monthly-toolbox-toolbox.unit"
    }]
  }, {
    path: "/employees/:id/edit",
    component: _500eab2e,
    name: "employees-id-edit"
  }, {
    path: "/employees/:id/view",
    component: _37d8b249,
    children: [{
      path: "",
      component: _347e9e0c,
      name: "employees-id-view"
    }, {
      path: "compliance",
      component: _a4c5525e,
      name: "employees-id-view-compliance"
    }, {
      path: "drug-alcohol",
      component: _521a63d2,
      name: "employees-id-view-drug-alcohol"
    }, {
      path: "files",
      component: _1736d531,
      name: "employees-id-view-files"
    }, {
      path: "forms",
      component: _5cebea2e,
      name: "employees-id-view-forms"
    }, {
      path: "induction",
      component: _41dc63fb,
      name: "employees-id-view-induction"
    }, {
      path: "linked",
      component: _fd0ec662,
      name: "employees-id-view-linked"
    }, {
      path: "medical",
      component: _6b4937cb,
      name: "employees-id-view-medical"
    }, {
      path: "ppe-tracker",
      component: _72563c8a,
      name: "employees-id-view-ppe-tracker"
    }, {
      path: "record-learning",
      component: _0eeb43d4,
      name: "employees-id-view-record-learning"
    }, {
      path: "training-records",
      component: _3d20ce85,
      name: "employees-id-view-training-records"
    }]
  }, {
    path: "/incidents/:id/edit",
    component: _860d0b5c,
    name: "incidents-id-edit"
  }, {
    path: "/machines/:machineid/edit",
    component: _45b98f58,
    name: "machines-machineid-edit"
  }, {
    path: "/machines/:machineid/files",
    component: _d42f66ce,
    name: "machines-machineid-files"
  }, {
    path: "/machines/:machineid/induction",
    component: _6e7fd0b4,
    name: "machines-machineid-induction"
  }, {
    path: "/machines/:machineid/prepurchase",
    component: _6c3682c9,
    name: "machines-machineid-prepurchase"
  }, {
    path: "/sites/:siteid/view",
    component: _6636777b,
    children: [{
      path: "",
      component: _2ca79484,
      name: "sites-siteid-view"
    }, {
      path: "covid-qr",
      component: _48f1170d,
      name: "sites-siteid-view-covid-qr"
    }, {
      path: "emergency-information",
      component: _1c7620dc,
      name: "sites-siteid-view-emergency-information"
    }, {
      path: "files",
      component: _6737263a,
      name: "sites-siteid-view-files"
    }, {
      path: "hazard-id",
      component: _16c4bda2,
      name: "sites-siteid-view-hazard-id"
    }, {
      path: "more-forms",
      component: _544227fb,
      name: "sites-siteid-view-more-forms"
    }, {
      path: "visitors",
      component: _b7ce43ee,
      name: "sites-siteid-view-visitors"
    }]
  }, {
    path: "/sites/:siteid/visitor",
    component: _74887a88,
    children: [{
      path: "",
      component: _563e09ea,
      name: "sites-siteid-visitor"
    }, {
      path: "addressbook",
      component: _c3e25654,
      name: "sites-siteid-visitor-addressbook"
    }, {
      path: "contractor",
      component: _40aaf68c,
      name: "sites-siteid-visitor-contractor"
    }, {
      path: "infrequent",
      component: _454f500a,
      name: "sites-siteid-visitor-infrequent"
    }]
  }, {
    path: "/vehicles/:vehicleid/edit",
    component: _789dd98e,
    name: "vehicles-vehicleid-edit"
  }, {
    path: "/contractors/:companyid/reviews/new",
    component: _aeb6a8a8,
    name: "contractors-companyid-reviews-new"
  }, {
    path: "/crews/:crewid/audits/new",
    component: _6378cec2,
    name: "crews-crewid-audits-new"
  }, {
    path: "/crews/:crewid/emergency-drill/new",
    component: _aa04bcec,
    name: "crews-crewid-emergency-drill-new"
  }, {
    path: "/crews/:crewid/safe-start-stop/new",
    component: _ec4ff190,
    name: "crews-crewid-safe-start-stop-new"
  }, {
    path: "/crews/:crewid/safety-meeting/new",
    component: _2d3eece7,
    name: "crews-crewid-safety-meeting-new"
  }, {
    path: "/crews/:crewid/safety-review/health-representative-review",
    component: _1753c349,
    name: "crews-crewid-safety-review-health-representative-review"
  }, {
    path: "/crews/:crewid/safety-review/new",
    component: _d56ef0c4,
    name: "crews-crewid-safety-review-new"
  }, {
    path: "/crews/:crewid/safety-review/site-supervisor-review",
    component: _50b62249,
    name: "crews-crewid-safety-review-site-supervisor-review"
  }, {
    path: "/crews/:crewid/safety-review/worker-review",
    component: _3fef60f6,
    name: "crews-crewid-safety-review-worker-review"
  }, {
    path: "/crews/:crewid/safety-sheets/add",
    component: _8dca2c8a,
    name: "crews-crewid-safety-sheets-add"
  }, {
    path: "/crews/:crewid/systems-audit/new",
    component: _11385edd,
    name: "crews-crewid-systems-audit-new"
  }, {
    path: "/employees/:id/forms/da-consent",
    component: _ffb20064,
    name: "employees-id-forms-da-consent"
  }, {
    path: "/employees/:id/forms/rol-request",
    component: _7f2f60bd,
    name: "employees-id-forms-rol-request"
  }, {
    path: "/employees/:id/medical-information/edit",
    component: _1fdf6c6d,
    name: "employees-id-medical-information-edit"
  }, {
    path: "/employees/:id/record-learning/request",
    component: _17605d26,
    name: "employees-id-record-learning-request"
  }, {
    path: "/machines/:machineid/induction/edit",
    component: _35e77ee6,
    name: "machines-machineid-induction-edit"
  }, {
    path: "/machines/:machineid/prepurchase/new",
    component: _b0c30f52,
    name: "machines-machineid-prepurchase-new"
  }, {
    path: "/sites/:siteid/emergency-information/edit",
    component: _a0e73bc2,
    name: "sites-siteid-emergency-information-edit"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/new",
    component: _5e44836f,
    name: "sites-siteid-enviromentalchecklist-new"
  }, {
    path: "/sites/:siteid/hazard-id/edit",
    component: _5e7e5c19,
    name: "sites-siteid-hazard-id-edit"
  }, {
    path: "/sites/:siteid/presite/new",
    component: _453db115,
    name: "sites-siteid-presite-new"
  }, {
    path: "/crews/:crewid/sbos/new/:formid?",
    component: _6c389b09,
    name: "crews-crewid-sbos-new-formid"
  }, {
    path: "/crews/:crewid/toolbox/new/:toolboxid?",
    component: _704fa050,
    name: "crews-crewid-toolbox-new-toolboxid"
  }, {
    path: "/crews/:crewid/sbos/view/:formid?/:id?",
    component: _10f25ab5,
    name: "crews-crewid-sbos-view-formid-id"
  }, {
    path: "/crews/:crewid/toolbox/view/:formid?/:id?",
    component: _67f95f31,
    name: "crews-crewid-toolbox-view-formid-id"
  }, {
    path: "/contractors/:companyid/reviews/:id",
    component: _d6225274,
    name: "contractors-companyid-reviews-id"
  }, {
    path: "/crews/:crewid/audits/:id?",
    component: _8ae4788e,
    name: "crews-crewid-audits-id"
  }, {
    path: "/crews/:crewid/emergency-drill/:drillid?",
    component: _5b27a306,
    name: "crews-crewid-emergency-drill-drillid"
  }, {
    path: "/crews/:crewid/safe-start-stop/:id?",
    component: _76223252,
    name: "crews-crewid-safe-start-stop-id"
  }, {
    path: "/crews/:crewid/safety-meeting/:meetingid?",
    component: _4c536ac0,
    name: "crews-crewid-safety-meeting-meetingid"
  }, {
    path: "/crews/:crewid/systems-audit/:id?",
    component: _04faec12,
    name: "crews-crewid-systems-audit-id"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/:id?",
    component: _4a8eae89,
    name: "sites-siteid-enviromentalchecklist-id"
  }, {
    path: "/sites/:siteid/presite/:id?",
    component: _3187dc2f,
    name: "sites-siteid-presite-id"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting",
    component: _02eea3e4,
    name: "crews-crewid-safety-review-id-meeting"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting.unit",
    component: _bdf0855e,
    name: "crews-crewid-safety-review-id-meeting.unit"
  }, {
    path: "/crews/:crewid/safety-review/:id?/:type?",
    component: _707919fc,
    name: "crews-crewid-safety-review-id-type"
  }, {
    path: "/contractors/:companyid/:id",
    component: _988d2886,
    name: "contractors-companyid-id"
  }, {
    path: "/sop/:id?/:version?",
    component: _0092face,
    name: "sop-id-version"
  }, {
    path: "/contractors/:companyid/:id?/view",
    component: _14c1d30a,
    children: [{
      path: "",
      component: _b0bb2be6,
      name: "contractors-companyid-id-view"
    }, {
      path: "file",
      component: _ef0d7bde,
      name: "contractors-companyid-id-view-file"
    }, {
      path: "forms",
      component: _44add6ea,
      name: "contractors-companyid-id-view-forms"
    }, {
      path: "inductions",
      component: _266c4927,
      name: "contractors-companyid-id-view-inductions"
    }]
  }, {
    path: "/contractors/:companyid/:id?/worker-information/edit",
    component: _44515ab2,
    name: "contractors-companyid-id-worker-information-edit"
  }, {
    path: "/",
    component: _f7a27686,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
